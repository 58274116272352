

import { ref } from '@vue/reactivity'
import { projectStorage } from "../firebase/config";


const useStorage = () => {
    const error = ref(null);
  
    // url for download the image
    const url = ref(null);
    const filePath  = ref('')
    const uploadImage = async (file,path) => {
      console.log(file.name);
      filePath.value = `${path}/${file.name}`;
      const storageRef = projectStorage.ref(filePath.value);
  
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    };
  
    // Delete the file
    const deleteImage = async path => {
      const storageRef = projectStorage.ref(path);
  
      try {
        await storageRef.delete();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    };
  
    const delete_image_by_url = async(url)=>{
      const storageRef = projectStorage.refFromURL(url);
      try {
        await storageRef.delete();
      } catch (err) {
        console.log(err.message);
        error.value = err.message;
      }
    }
  
    return { error, url, filePath, uploadImage, deleteImage,delete_image_by_url };
};
  
export default useStorage;