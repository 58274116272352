import { createRouter, createWebHistory } from 'vue-router'
import { projectFunctions } from '../firebase/config';
import Home from '../views/Home.vue'

const require_auth_admin = async (to, from, next) => {
  const admin = await projectFunctions.httpsCallable("check_admin")();
  if (!admin) {
    next('/');
  } else {
    next();
  }
};


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/success-payment-page',
    name: 'success-page',
    component: () => import('../views/Users/paymentsuccess.vue')
  },
  {
    path: '/takanon',
    name: 'takanon',
    component: () => import('../views/takanon.vue')
  },
  {
    path: '/negishut',
    name: 'negishut',
    component: () => import('../views/negishut.vue')
  },
  {
    path: '/pratiut',
    name: 'pratiut',
    component: () => import('../views/pratiut.vue')
  },
  {
    path: '/signmaker',
    name: 'signmaker',
    component: () => import('../views/Tests/SignMaker/SignMaker.vue')
  },
  {
    path: '/joinb2b',
    name: 'joinb2b',
    component: () => import('../views/newB2BClient.vue')
  },
  {
    path: '/login',
    name: 'login',
    component:  () => import('../views/Login.vue')
  },
  {
    path: '/profileup',
    name: 'profileup',
    component:  () => import('../views/Users/UpdateProfile.vue')
  },
  {
    path: '/profilepage',
    name: 'profilepage',
    component:  () => import('../views/Users/ProfilePage.vue')
  },
  {
    path: '/agentsportal',
    name: 'agentsportal',
    component:  () => import('../views/Agents/AgentsPortal.vue')
  },
  {
    path: '/agentroute',
    name: 'agentroute',
    component:  () => import('../views/Agents/AgentRoute.vue')
  },
  {
    path: '/agentclient',
    name: 'agentclient',
    component:  () => import('../views/Agents/AgentClient.vue')
  },
  {
    path: '/agentsales',
    name: 'agentsales',
    component:  () => import('../views/Agents/AgentSales.vue')
  },
  {
    path: '/cmap',
    name: 'cmap',
    component:  () => import('../views/CompanyMap/CMap.vue')
  },
  {
    path: '/routeplanner',
    name: 'routeplanner',
    component:  () => import('../views/CompanyMap/RoutePlanner.vue')
  },
  {
    path: '/potentialClients',
    name: 'potentialClients',
    component:  () => import('../views/CompanyMap/PotentialClients.vue')
  },
  {
    path: '/agentai',
    name: 'agentai',
    component:  () => import('../views/Tests/AgentAi.vue')
  },
  {
    path: '/agentnewclient',
    name: 'agentnewclient',
    component:  () => import('../views/Agents/AgentNewClient.vue')
  },
  {
    path: '/agentupdatecredit',
    name: 'agentupdatecredit',
    component:  () => import('../views/Agents/AgentUpdateCredit.vue')
  },
  {
    path: '/gvia',
    name: 'gvia',
    component:  () => import('../views/Agents/AgentGvia.vue')
  },
  {
    path: '/agentmsgs',
    name: 'agentmsgs',
    component:  () => import('../views/Agents/AgentMsgs.vue')
  },
  {
    path: '/goals',
    name: 'goals',
    component:  () => import('../views/Goals/Goals.vue')
  },
  {
    path: '/finilizecart',
    name: 'finilizecart',
    component:  () => import('../views/FnilizeOrder.vue')
  },
  {
    path: '/paymentsuccesspage',
    name: 'paymentsuccesspage',
    component:  () => import('../views/SuccessPage.vue')
  },
  {
    /* Agents Gvia */
    path: '/success-payment-gvia-page',
    name: 'SuccessPaymentPage',
    component: () => import('../components/Agents/Gvia/SuccessPaymentPage.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    beforeEnter:require_auth_admin,
    component:  () => import('../views/Admin/AdminMain.vue'),
    children:[
      // {
      //   path: '/admin/',
      //   name: 'AdminPanel',
      //   component:  () => import('../views/Admin/AdminPanel.vue')
      // },
      {
        path: '/admin/',
        name: 'AdminLoading',
        component:  () => import('../views/Admin/AdminLoading/AdminLoading.vue')
      },
      {
        path: '/admin/catalog/:makat?',
        name: 'AdminCatalog',
        component:  () => import('../views/Admin/AdminCatalog.vue'),
        props:true
      },
      {
        path: '/admin/categories',
        name: 'AdminCategories',
        component:  () => import('../views/Admin/AdminCategories.vue')
      },
      {
        path: '/admin/LeadsForm',
        name: 'LeadsForm',
        component:  () => import('../views/Admin/Leads/LeadsForm.vue')
      },
      {
        path: '/admin/WhatsApp',
        name: 'WhatsApp',
        component:  () => import('../views/Admin/WhatsApp/WhatsApp.vue')
      },
      {
        path: '/admin/clients',
        name: 'AdminClients',
        component:  () => import('../views/Admin/AdminClients.vue')
      },
      {
        path: '/admin/users',
        name: 'AdminUsers',
        component:  () => import('../views/Admin/AdminUsers.vue')
      },
      {
        path: '/admin/orders',
        name: 'AdminOrders',
        component:  () => import('../views/Admin/AdminOrders.vue')
      },
      {
        path: '/admin/admin-system',
        name: 'AdminSystem',
        component:  () => import('../views/Admin/AdminSystem.vue')
      },
      {
        path: '/admin/admin-scenarios',
        name: 'AdminScenarios',
        component:  () => import('../views/Admin/AdminScenarios/AdminScenarios.vue')
      },
      {
        path: '/admin/leads',
        name: 'Leads',
        component:  () => import('../views/Admin/Leads/Leads.vue')
      },
      {
        path: '/admin/sub-items/:sub_uid',
        name: 'SubItems',
        component:  () => import('../components/Admin/Catalog/SubItems.vue'),
        props:true
      }
    ]
  },
  {
    path: '/admin-routes',
    name: 'AdminRoutes',
    component:  () => import('../views/Admin/Reports/AgentRoutes/AgentRoutes.vue'),
    beforeEnter:require_auth_admin,
  },
  {
    path: '/admin-routes',
    name: 'AdminRoutes',
    component:  () => import('../views/Admin/Reports/AgentRoutes/AgentRoutes.vue'),
    beforeEnter:require_auth_admin,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
